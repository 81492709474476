.onboarding-slider {
    height: 100%;
    width: 100vw;
}

.close-button-login-onboarding {
    position: absolute;
    top: 0;
    right: 0;
    margin: 1rem;
    z-index: 1 !important;
}

.close-button-login-onboarding-white {
    color: white !important;
}

.bottom-bar {
    position: absolute;
    bottom: 0;
    z-index: 100;
    width: 100%;
    padding: 1.5rem;
}

.bottom-bar-white button {
    color: white;
}

.swiper-pagination {
    bottom: 2rem !important;
    z-index: 1000;
}