.animated-tab {
    position: absolute;
    display: flex !important;
    flex-direction: column;
    top: 40px;
    width: 100%;
    height: calc(100% - 40px);
    transition: transform 0.250s ease, top 0.250s ease, height 0.250s ease;
}

.animated-tab-scrollbox {
    height: 100%;
    overflow-y: auto;
}

.animate-out-right {
    transform: translateX(100%);
}

.animate-out-left {
    transform: translateX(-100%);
}

.animate-in {
    opacity: 1 !important;
}

.full-flex {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.hide-tab {
    opacity: 0;
}

.ellipsis {
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
    overflow: hidden !important;
    display: block !important;
}

.topbar {
    z-index: 10;
}

.topbar::after {
    content: '';
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 40px;
    opacity: 0;
    box-shadow: 0 9px 16px rgba(0, 0, 0, 0.5);
    transition: opacity 0.2s ease-in-out;
}

.topbar.topbar-shadow::after {
    opacity: 1;
}

.no-start-offset {
    top: 0 !important;
    height: 100% !important;
}